import React from "react";
import * as Comp from 'components/index';
import * as Ui from "components/ui";
import * as Sections from "components/sections";

const content = function (props = null) {
  let subject = (props && props.subject) ? props.subject : ''
  let keyRef = (props && props.keyRef) ? props.keyRef : 'default'
  return {
      preTitle: "Control your backups",
    title: (<>Control where you store your <span className="text-primary">{subject}</span> backups</>),
      description: (
        <>
          <p>
            <span className="link-highlight">Store {subject} backups on any storage</span>.
            Gain the flexibility to choose a storage solution that best fits your infrastructure, be it off-site, cloud-based or on-premises.
            <div className="my-6">
              <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Connect any storage provider</p>
              <Sections.StorageProvidersList subject={subject} />
            </div>
            <Ui.FeatureChecks items={['Bring your own storage', 'SimpleStorage', 'S3 Compatible storage', '50+ Cloud Storage services']} />
            <Ui.Link arrow="right" to="/catalog">Discover all integrations</Ui.Link>
          </p>
        </>
      ),
      image: ( <Comp.Image
        className=""
        filename={`simplebackups-${keyRef}-backup-automation-providers.png`}
        alt={`Backup ${subject} to any storage provider`}
        style={{maxWidth: 550}}
      />)
    }
};

export default content;